import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import { Talent } from 'helpers/Talent'
import useLocalStorage from 'hooks/useLocalStorage'
import _, { set } from 'lodash'
import { fetchOneUserSheet, setNameFilter, debouncedSaveUserSheet } from 'redux/userSheetSlice'
import { useParams } from 'react-router'
import { useDebounce } from 'use-debounce'

import {
  EuiFlexItem,
  EuiPanel,
  EuiPageTemplate,
  EuiButton,
  EuiFieldText,
  EuiPopover,
  EuiFormRow,
} from '@elastic/eui'

import { Link } from 'react-router-dom'

import { useEuiTheme } from '@elastic/eui'

import InfoPopover from 'components/InfoPopover'
import {
  addSheetToCollection,
  fetchCollection,
  setSalesSheets,
  setTitle,
  saveCollection,
  saveCollectionTitle,
  addSheetToCollectionAndSave,
  updateCollectionSheetDescription,
  removeSheetFromCollectionAndSave,
} from 'redux/collectionsSlice'
import { fetchSheetsForCollection } from 'redux/allSheetsSlice'
import axios from 'axios'
import { updateSheetDescription } from 'redux/allSheetsSlice'
import DraggableGrid from 'components/DraggableGrid'

const filterByTitle = (sheet, titleSearchTerm) => {
  const search = titleSearchTerm.toLowerCase()
  const result = sheet?.title && sheet.title.toLowerCase().includes(search)
  return result
}

const AvailableSheetsTable = ({ sheetTitleFilter = '' }) => {
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(80)

  const dispatch = useDispatch()

  const params = useParams()

  const collectionId = params.collectionId

  const collection = useSelector((state) => {
    let collectionId = params.collectionId
    return state.collections.collections[collectionId]
  })

  const allSheets = useSelector((state) => state.allSheets)

  const availableSheets = useSelector((state) => state.collections.sheets)

  const totalPages = Math.ceil(allSheets.ordered_sheets.length / pageSize)

  useEffect(() => {
    if (sheetTitleFilter.length >= 2) {
      setPageIndex(0)
    }
  }, [sheetTitleFilter])

  let filteredSheets = useMemo(() => {
    let filtered_sheets = _.orderBy(availableSheets, ['created_at'], ['desc'])
    const added_sheets = collection?.salesheet_ids || []

    filtered_sheets = filtered_sheets.filter((sheet) => !added_sheets.includes(sheet.id))

    if (sheetTitleFilter.length >= 2) {
      filtered_sheets = filtered_sheets.filter((sheet) => {
        return filterByTitle(sheet, sheetTitleFilter)
      })
    }

    filtered_sheets = filtered_sheets.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)

    return filtered_sheets.filter((s) => s !== undefined)
  }, [availableSheets, collection, pageIndex, sheetTitleFilter])

  const nameFilter = useSelector((state) => state.userSheet.name_filter)

  useEffect(() => {
    // when this changes, we need to reset the page index to 0
    setPageIndex(0)
  }, [nameFilter])

  const tableColumns = [
    {
      header: 'Talent Sheet',
      render: (sheet) => {
        return (
          <div className="d-flex flex-row align-items-center">
            <div className="fw-bold fs-5">{sheet?.title}</div>
          </div>
        )
      },
    },
    {
      header: '',
      render: (sheet) => {
        return (
          <div className="d-flex flex-row justify-content-end">
            <button
              className="btn btn-bright-blue py-3 px-4 me-4"
              onClick={() => {
                console.log('add', sheet)
                dispatch(
                  addSheetToCollectionAndSave({
                    collection_id: collectionId,
                    sheet_id: sheet.id,
                  }),
                )
              }}
            >
              Add +
            </button>
          </div>
        )
      },
    },
  ]

  return (
    <div className="row mt-3">
      <div className="col-12">
        <div
          style={{
            height: '65vh',
            overflowY: 'auto',
          }}
          className="eui-scrollBar w-100"
        >
          <table
            // items={paginatedItems}
            // columns={columns}
            // pagination={pagination}
            // onChange={onTableChange}
            className="table"
          >
            <thead>
              <tr>
                {tableColumns.map((column, i) => {
                  return (
                    <th
                      key={`column-${i}`}
                      style={{
                        fontSize: '1.1em',
                      }}
                      className="bg-gray-800 text-white py-3"
                    >
                      {column.header}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {filteredSheets.map((sheet, i) => {
                return (
                  <tr
                    style={{
                      borderBottom: '1px solid #e0e0e0',
                    }}
                    key={`sheet-${i}`}
                  >
                    {tableColumns.map((column, j) => {
                      return <td key={`column-${j}`}>{column.render(sheet)}</td>
                    })}
                    {/* <td>
                      <div className="d-flex flex-row align-items-center">
                        <EuiFlexItem
                          css={css`
                            border-radius: 10px;
                            overflow: hidden;
                          `}
                          grow={false}
                        ></EuiFlexItem>
                        <EuiFlexItem className="fw-bold ms-3">{sheet?.title}</EuiFlexItem>
                      </div>
                    </td>
                    <td>
                      <EuiButton
                        fill
                        size="s"
                        onClick={() => {
                          console.log('add', sheet)
                        }}
                      >
                        Add
                      </EuiButton>
                    </td> */}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-end">
        <button
          className="btn btn-link"
          disabled={pageIndex === 0}
          onClick={() => setPageIndex((prevPageIndex) => prevPageIndex - 1)}
        >
          Previous Group
        </button>
        <button
          className="btn btn-link"
          disabled={pageIndex >= totalPages - 1}
          onClick={() => setPageIndex((prevPageIndex) => prevPageIndex + 1)}
        >
          Next Group
        </button>
      </div>
    </div>
  )
}

const NewEditCollection = ({}) => {
  const { euiTheme } = useEuiTheme()
  const dispatch = useDispatch()
  const params = useParams()
  const [editTitle, setEditTitle] = useState(false)

  const [collectionTitleInput, setCollectionTitleInput] = useState('')
  const [sheetTitleFilter, setSheetTitleFilter] = useState('')

  const [debouncedSheetTitleFilter] = useDebounce(sheetTitleFilter, 100)

  const [gridView, setGridView] = useLocalStorage(false)

  const [isPopoverOpen, setIsPopoverOpen] = useState(null)
  const [publicTitle, setPublicTitle] = useState('')

  const onButtonClick = (sheet_id) => {
    // if popover not open, set it to the sheet id
    if (isPopoverOpen !== sheet_id) {
      setIsPopoverOpen(sheet_id)
    } else {
      setIsPopoverOpen(null)
    }
  }
  const closePopover = () => setIsPopoverOpen(false)
  const savePublicTitle = (sheet) => {
    // Save the public title here
    const savePublicSheetHeading = (newHeading, sheet_id) => {
      return axios.post('/api/user/talent-sheets/public-title', {
        public_title: newHeading,
        sheet_id,
      })
    }

    console.log('save public title', publicTitle, sheet)

    if (!_.isEmpty(publicTitle)) {
      savePublicSheetHeading(publicTitle, sheet.id).then((res) => {
        dispatch(updateSheetDescription({ id: sheet.id, description: publicTitle }))
        dispatch(
          updateCollectionSheetDescription({
            sheet_id: sheet.id,
            description: publicTitle,
          }),
        )
        setPublicTitle('')
      })
    }

    closePopover()
  }

  const collectionId = params.collectionId

  const collection = useSelector((state) => {
    let collectionId = params.collectionId
    return state.collections.collections[collectionId]
  })

  useEffect(() => {
    const load = async () => {
      if (params.collectionId !== 'new-collection') {
        dispatch(fetchCollection({ collection_id: collectionId }))
        setEditTitle(false)
      }
    }

    load()
  }, [dispatch, params.collectionId])

  useEffect(() => {
    dispatch(fetchSheetsForCollection({}))
  }, [dispatch])

  const handleRemove = (sheet_id) => {
    const nextSheets = collection.salesheet_ids.filter((id) => id !== sheet_id)

    dispatch(
      removeSheetFromCollectionAndSave({
        collection_id: collection.id,
        sheet_id,
      }),
    )
  }

  const allInfluencerEntities = useSelector((state) => state.influencers.entities)
  const socials = useSelector((state) => state.socials.entities)

  useEffect(() => {
    const load = async () => {
      if (params.talentSheetId && params.talentSheetId !== 'new-talent-sheet') {
        dispatch(fetchOneUserSheet({ hash_id: params.talentSheetId }))
        setEditTitle(false)
      }
    }
    load()
  }, [dispatch, params.talentSheetId])

  let handleFilterChange = (e) => {
    dispatch(setNameFilter(e.target.value))
  }

  handleFilterChange = _.debounce(handleFilterChange, 350)

  const collectionSheets = useSelector((state) => state.collections.sheets)

  if (!collection) {
    return null
  }

  return (
    <div>
      <div
        className="container-fluid"
        style={{
          backgroundColor: euiTheme.colors.lightestShade,
        }}
      >
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex flex-row py-3">
                      {editTitle ? (
                        <>
                          <EuiFormRow
                          // label="Sheet Title"
                          >
                            <EuiFieldText
                              name="name"
                              value={collectionTitleInput}
                              onChange={(e) => {
                                setCollectionTitleInput(e.target.value)
                              }}
                            />
                          </EuiFormRow>
                          <div className="d-flex align-items-center">
                            <EuiButton
                              className="ms-3"
                              onClick={() => {
                                console.log('save title')
                                dispatch(
                                  saveCollectionTitle({
                                    collection_id: collection.id,
                                    title: collectionTitleInput,
                                  }),
                                )
                                setEditTitle(false)
                              }}
                              fill
                            >
                              Save
                            </EuiButton>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              fontSize: '2.5rem',
                            }}
                            className="fw-bold"
                          >
                            {collection.title}
                          </div>
                          <button
                            className="btn btn-link"
                            onClick={() => {
                              setEditTitle(true)
                              setCollectionTitleInput(collection.title)
                            }}
                          >
                            Edit Title
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <EuiPanel paddingSize="m">
                <div className="container-fluid my-3">
                  <div className="my-1">
                    {params.collectionId !== 'new-collection' && (
                      <div className="row">
                        <p className="col-6" style={{ fontSize: '1.1em' }}>
                          <Link
                            data-purpose="roster-view-public-collection-link"
                            to={`/public/collection/${params.collectionId}`}
                          >
                            Public Link for Sharing
                          </Link>
                        </p>
                        <p className="col-6" style={{ fontSize: '1.1em' }}>
                          <Link
                            data-purpose="roster-view-public-collection-link"
                            to={`/public/collection/view-all/${params.collectionId}`}
                          >
                            Alternate View
                          </Link>
                          <InfoPopover
                            title="Alternate View"
                            content="This works best when the individual sheets are not very large (i.e. less than 50-60 influencers per sheet)."
                          />
                        </p>
                      </div>
                    )}
                    <hr />
                  </div>
                  <div className="row mt-2">
                    <div className="col-10">
                      <h2 className="fs-1 fw-bold">Available Sheets</h2>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <EuiFlexItem>
                    <EuiFieldText
                      value={sheetTitleFilter}
                      placeholder="Filter by name"
                      onChange={(e) => {
                        setSheetTitleFilter(e.target.value)
                      }}
                    />
                  </EuiFlexItem>
                  {/* <EuiSpacer /> */}
                </div>

                <AvailableSheetsTable sheetTitleFilter={debouncedSheetTitleFilter} />
              </EuiPanel>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <EuiPanel paddingSize="l" color="transparent" className="py-0">
              <div className="container-fluid">
                <div className="row my-3">
                  <div className="col-8">
                    <h2 className="fs-1 fw-bold">Sheets Added</h2>
                  </div>
                  <div className="col-4 d-flex justify-content-end">
                    <p
                      className="selectable bg-white align-content-center"
                      style={{ borderRadius: '10px', padding: '5px 10px' }}
                      onClick={() => {
                        dispatch(saveCollection(collection))
                      }}
                    >
                      {collection.saveState === 'dirty' ? 'Saving...' : 'Saved'}
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: '85vh',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}
                className="eui-scrollBar w-100 mt-2 pt-2"
              >
                {/* <DraggableGrid
                  items={collection.salesheet_ids}
                  RenderGridPanel={({ item }) => {
                    const sheet = _.find(collectionSheets, {
                      id: item,
                    })
                    const sheetInfluencerIds = sheet?.influencer_ids || []

                    const sheetInfluencers = sheetInfluencerIds
                      .slice(0, 3)
                      .map((influencer_id) => {
                        return allInfluencerEntities[influencer_id]
                      })

                    // const influencer = influencers[influencer_id]
                    if (!sheet) return null

                    const isOpen = isPopoverOpen === item

                    return (
                      <div className="col-12" key={`sheet-${item}`}>
                        <div className="card p-3 shadow-sm">
                          <div className="row">
                            <div className="col-3 my-auto">
                              <EuiPopover
                                button={
                                  <span
                                    className="fw-bold selectable"
                                    onClick={() => {
                                      onButtonClick(item)
                                    }}
                                  >
                                    {_.isEmpty(sheet.description) ? (
                                      <span style={{ color: 'red' }}>Need Public Title</span>
                                    ) : (
                                      sheet.description
                                    )}
                                  </span>
                                }
                                isOpen={isOpen}
                                closePopover={closePopover}
                              >
                                <EuiFieldText
                                  value={publicTitle}
                                  placeholder="Change Display Name"
                                  onChange={(e) => setPublicTitle(e.target.value)}
                                  aria-label="Public title input"
                                />
                                <EuiButton
                                  className="mt-2"
                                  onClick={() => {
                                    savePublicTitle(sheet)
                                  }}
                                >
                                  Save
                                </EuiButton>
                              </EuiPopover>
                            </div>
                            <div className="col-4 d-flex">
                              {sheetInfluencers.map((influencer) => {
                                const talent = new Talent(influencer, socials)

                                if (!talent.influencer) return null

                                return (
                                  <div key={influencer.id} className="me-2">
                                    <img
                                      src={talent.getPhotoUrl()}
                                      alt={influencer.fullname}
                                      style={{
                                        borderRadius: '10px',
                                        height: '60px',
                                      }}
                                    />
                                  </div>
                                )
                              })}
                            </div>
                            <div className="col-5 d-flex justify-content-end">
                              <button
                                style={{ minWidth: '110px' }}
                                className="btn btn-bright-red p-3"
                                onClick={() => {
                                  console.log('remove', item)
                                  handleRemove(item)
                                }}
                                size="s"
                              >
                                Remove -
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }}
                  onDropComplete={(newOrder) => {
                    console.log(newOrder)
                    dispatch(
                      setSalesSheets({
                        collection_id: collection.id || 'new-collection',
                        salesheet_ids: newOrder,
                      }),
                    )
                  }}
                /> */}
                <DragDropContext
                  onDragEnd={(result) => {
                    const reorder = (list, startIndex, endIndex) => {
                      const result = Array.from(list)
                      const [removed] = result.splice(startIndex, 1)
                      result.splice(endIndex, 0, removed)

                      return result
                    }

                    const { source, destination } = result

                    if (!destination) {
                      return
                    }
                    const sInd = +source.droppableId
                    const dInd = +destination.droppableId

                    if (sInd === dInd) {
                      const newSalesSheetOrder = reorder(
                        collection.salesheet_ids,
                        source.index,
                        destination.index,
                      )

                      console.log(newSalesSheetOrder)

                      dispatch(
                        setSalesSheets({
                          collection_id: collection.id || 'new-collection',
                          salesheet_ids: newSalesSheetOrder,
                        }),
                      )
                    }
                  }}
                >
                  <Droppable droppableId={'0'}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          className={`row`}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {collection.salesheet_ids.map((sheet_id, i) => {
                            const sheet = _.find(collectionSheets, {
                              id: sheet_id,
                            })

                            const sheetInfluencerIds = sheet?.influencer_ids || []

                            const sheetInfluencers = sheetInfluencerIds
                              .slice(0, 3)
                              .map((influencer_id) => {
                                return allInfluencerEntities[influencer_id]
                              })

                            // const influencer = influencers[influencer_id]
                            if (!sheet) return null

                            const isOpen = isPopoverOpen === sheet_id

                            return (
                              <Draggable key={sheet_id} draggableId={sheet_id} index={i}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className="col-12 mb-2" key={`sheet-${sheet_id}`}>
                                      <div className="card p-3 shadow-sm">
                                        <div className="row">
                                          <div className="col-3 my-auto">
                                            <EuiPopover
                                              button={
                                                <span
                                                  className="fw-bold selectable"
                                                  onClick={() => {
                                                    onButtonClick(sheet_id)
                                                  }}
                                                >
                                                  {_.isEmpty(sheet.description)
                                                    ? 'Add Public Title'
                                                    : sheet.description}
                                                </span>
                                              }
                                              isOpen={isOpen}
                                              closePopover={closePopover}
                                            >
                                              <EuiFieldText
                                                value={publicTitle}
                                                placeholder="Change Display Name"
                                                onChange={(e) => setPublicTitle(e.target.value)}
                                                aria-label="Public title input"
                                              />
                                              <EuiButton
                                                className="mt-2"
                                                onClick={() => {
                                                  savePublicTitle(sheet)
                                                }}
                                              >
                                                Save
                                              </EuiButton>
                                            </EuiPopover>
                                          </div>
                                          <div className="col-4 d-flex">
                                            {sheetInfluencers.map((influencer) => {
                                              const talent = new Talent(influencer, socials)

                                              if (!talent.influencer) return null

                                              return (
                                                <div key={influencer.id} className="me-2">
                                                  <img
                                                    src={talent.getPhotoUrl()}
                                                    alt={influencer.fullname}
                                                    style={{
                                                      borderRadius: '10px',
                                                      height: '60px',
                                                    }}
                                                  />
                                                </div>
                                              )
                                            })}
                                          </div>
                                          <div className="col-5 d-flex justify-content-end">
                                            <button
                                              style={{ minWidth: '110px' }}
                                              className="btn btn-bright-red p-3"
                                              onClick={() => {
                                                console.log('remove')
                                                handleRemove(sheet_id)
                                              }}
                                              size="s"
                                            >
                                              Remove -
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            )
                          })}
                          {provided.placeholder}
                        </div>
                      )
                    }}
                  </Droppable>
                </DragDropContext>
              </div>
            </EuiPanel>
          </div>
        </div>
        {/* <EuiBottomBar paddingSize="s">
          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              {talentSheet.saveStatus === 'clean' ? 'Saved' : 'Saving...'}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiBottomBar> */}
      </div>
    </div>
  )
}

export default NewEditCollection
