import { EuiFlexGroup, EuiFlexItem, EuiText, EuiStat } from '@elastic/eui'
import SocialIcon from 'components/SocialIcon'
import { getDisplayName } from 'helpers/Social'
import 'react-circular-progressbar/dist/styles.css'
import { joinClassNames, safelyMillify } from 'utils'
import { BiUpArrowAlt, BiDownArrowAlt } from 'react-icons/bi'
import config from 'config'
import { getStatChanges } from 'utils'

const DisplayChange = ({ change }) => {
  if (!change) return null

  return (
    <div className={change > 0 ? 'text-success' : 'text-danger'}>
      <span
        style={{
          backgroundColor: change > 0 ? config.colors['light-green'] : config.colors['light-red'],
          padding: '4px',
          borderRadius: '4px',
        }}
      >
        {change > 0 ? (
          <BiUpArrowAlt className="text-success" />
        ) : (
          <BiDownArrowAlt className="text-danger" />
        )}
        {change.toFixed(2).replace('-', '')}%
      </span>
    </div>
  )
}

const SocialMediaStats = ({ talent, selectedSocial, setSelectedSocial, withText = true }) => {
  const { allSocials } = talent.getSocials()

  const totalReach = allSocials.reduce((acc, social) => acc + social.followers, 0)

  return (
    <div className="card p-3 w-100 shadow-sm flex-fill">
      <div className="mb-3">
        <EuiStat title={safelyMillify(totalReach)} description="" titleSize="s">
          <p
            style={{
              fontSize: '0.85rem',
            }}
            className="text-eui-gray"
          >
            TOTAL REACH
          </p>
        </EuiStat>
      </div>

      <div
        className="d-flex flex-column justify-content-between"
        style={{
          minHeight: '50%',
        }}
      >
        {allSocials.map((stat, index) => {
          const getFollowerChange = () => {
            const stat_history = stat.stat_history

            if (!stat_history || stat_history.length < 2) return false
            // get last two elements from the array
            const lastTwoElements = stat_history.slice(-2)

            // calculate the percentage change between last and second-to-last elements in .followers property
            const followersPercentageChange =
              ((lastTwoElements[1].followers - lastTwoElements[0].followers) /
                lastTwoElements[0].followers) *
              100

            return followersPercentageChange
          }

          const changes = getStatChanges(stat.stat_history)
          console.log(changes)

          const latestFollowerChange = changes?.followersPercentChange

          return (
            <div key={index}>
              <div className="row mb-2">
                <div className="col-5 d-flex justify-content-start">
                  <div className="me-1">
                    <SocialIcon platform={stat.platform} size={24} />
                  </div>
                  <div
                    onClick={() => setSelectedSocial(stat.id)}
                    className={joinClassNames([
                      selectedSocial === stat.id ? 'fw-bold text-decoration-underline' : null,
                      'selectable',
                    ])}
                  >
                    {getDisplayName(stat)}
                  </div>
                </div>
                <div className="col-3 text-center">
                  <EuiStat
                    title={safelyMillify(stat.followers)}
                    description={withText ? 'Followers' : ''}
                    titleSize="xs"
                    textAlign="center"
                  />
                </div>
                {latestFollowerChange !== 0 && (
                  <div
                    className={joinClassNames([
                      'col-4',
                      'text-center',
                      latestFollowerChange > 0 ? 'text-success' : 'text-danger',
                    ])}
                  >
                    <DisplayChange change={latestFollowerChange} />
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SocialMediaStats
