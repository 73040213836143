import { createSlice } from '@reduxjs/toolkit'

import _ from 'lodash'

export const modalsSlice = createSlice({
  name: 'modals',
  initialState: {
    contactInfo: false,
  },
  reducers: {
    setModalShow: (state, { payload }) => {
      state[payload.modal] = payload.show
    },
  },
})

// Action creators are generated for each case reducer function
export const { setModalShow } = modalsSlice.actions

export default modalsSlice.reducer
