import { useEffect, useMemo } from 'react'
import _ from 'lodash'
import { fetchPublicInfluencers } from './redux/influencerSlice'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'

import LoadingContainer from 'components/LoadingContainer'
import { useParams, useNavigate } from 'react-router-dom'
import RenderPublicRoster from 'RenderPublicRoster'
import NewRenderPublicRoster from 'NewRenderPublicRoster'
import { fetchPublicInfluencerProfile } from 'redux/influencerSlice'
import PublicProfilev2 from 'PublicProfiles/v2/PublicProfile_v2'
import { Talent } from 'helpers/Talent'

export default function NewPublicTalentRoster() {
  const params = useParams()
  const influencers = useSelector((state) => {
    const pseudoSheetId = `team-roster-${params.teamId}`
    const teamTalentSheet = state.allSheets.entities[pseudoSheetId]
    const influencerEntities = state.influencers.entities

    // return object of influencers that are in the team roster
    return _.pick(influencerEntities, _.get(teamTalentSheet, 'influencer_ids', []))
  })

  const publicRosterOrder = useSelector((state) => state.influencers.publicRosterOrder)

  const dispatch = useDispatch()
  const settings = useSelector((state) => state.influencers.teamSettings)
  const socials = useSelector((state) => state.socials.entities)

  const searchParams = new URLSearchParams(window.location.search)
  const navigate = useNavigate()
  const team = useSelector((state) => state.team)

  const teamSettings = useSelector((state) => {
    const team_id = params.teamId
    if (!team_id) return null
    return _.get(state, ['team', 'allTeamSettings', team_id]) || {}
  })

  useEffect(() => {
    const load = async () => {
      const teamId = params.teamId

      dispatch(fetchPublicInfluencers({ team_id: teamId }))
    }

    load()
  }, [dispatch])

  useEffect(() => {
    if (searchParams.get('influencer_id')) {
      dispatch(
        fetchPublicInfluencerProfile({
          influencer_id: searchParams.get('influencer_id'),
          team_id: params.teamId,
        }),
      )
    }
  }, [dispatch, params.teamId, searchParams.get('influencer_id')])

  const influencerArray = useMemo(() => {
    if (!influencers || _.isEmpty(influencers)) return []
    console.log(influencers)
    let influencerArray = _.orderBy(
      influencers,
      (influencer) => {
        const primary_social_id = _.get(influencer, 'primary_account_id')
        const primary_social = socials[primary_social_id]
        return _.get(primary_social, 'followers', 0)
      },
      'desc',
    )

    if (publicRosterOrder === 'engagement') {
      influencerArray = _.orderBy(
        influencers,
        (influencer) => {
          return _.get(influencer, 'primary_engagement_rate', 0)
        },
        'desc',
      )
    }

    if (publicRosterOrder === 'followers') {
      influencerArray = _.orderBy(
        influencers,
        (influencer) => {
          const talent = new Talent(influencer, socials)

          const allSocials = talent.getSocials().allSocials

          return allSocials.reduce((acc, social) => {
            if (!social.followers) return acc

            return acc + social.followers
          }, 0)
        },
        'desc',
      )
    }

    return influencerArray
  }, [influencers, socials, publicRosterOrder])

  if (!influencers || _.isEmpty(influencers)) {
    return <LoadingContainer />
  }

  const talentSheet = {
    settings: team.settings || {},
    team_name: teamSettings.name,
    description: `Talent Roster`,
    team_image_url: team.image_url,
    ...team,
  }

  if (searchParams.get('influencer_id')) {
    let selectedProfile = _.get(influencers, searchParams.get('influencer_id'))
    if (_.isEmpty(selectedProfile)) {
      return <LoadingContainer />
    }

    return (
      <PublicProfilev2
        influencerNote={null}
        influencer={selectedProfile}
        socials={socials}
        showBackArrow={true}
        onBack={() => {
          navigate(-1)
        }}
        settings={{
          ...team.settings,
          teamLogo: team.image_url,
          teamName: team.name,
          excluded_post_ids: _.get(selectedProfile, 'team_settings.excluded_post_ids', []),
        }}
        darkMode={talentSheet.settings.displayDarkMode}
        grayScale={talentSheet.settings.displayGrayScale}
        displaySettings={team.settings}
      />
    )
  }

  const displaySettings = _.isEmpty(talentSheet?.settings) ? team.settings : talentSheet.settings

  return (
    <>
      <NewRenderPublicRoster
        talentSheet={talentSheet}
        socials={socials}
        influencerArray={influencerArray}
        displayedInfluencers={influencerArray}
        totalPages={1}
        currentGroup={1}
        pageLimit={500}
        talentLoading={false}
        setTalentLoading={() => {}}
        sheetIsLoading={false}
        loadNextGroup={() => {}}
        source="Public Roster"
        displaySettings={displaySettings}
      />
    </>
  )
}
