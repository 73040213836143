import React, { Fragment, useEffect, useState, useRef, useMemo } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { fetchCollection, fetchPublicCollection } from './redux/collectionsSlice'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import PublicProfilev2 from 'PublicProfiles/v2/PublicProfile_v2'

import _ from 'lodash'
import { sendEvent } from 'analytics'
import {
  getContrastColor,
  joinClassNames,
  getContrastingTextForBackground,
  getColorHex,
} from 'utils'
import Color from 'color'
import config from './config'
import ContactInfoModal from 'components/ContactInfoModal'
import { setModalShow } from 'redux/modalsSlice'
import RenderRosterItems from 'RenderRosterItems'
import { fetchSingleSheet } from 'redux/allSheetsSlice'
import LoadingContainer from './components/LoadingContainer.js'
import TalentSheetBranding from 'TalentSheetBranding'
import { useInView } from 'react-intersection-observer'
import { Button } from 'react-bootstrap'
import { Offcanvas } from 'react-bootstrap'
import { fetchSingleProfileForSheet } from './redux/talentSheetSlice'

const getBackgroundTextContrast = (backgroundColor) => {
  backgroundColor = config.colors[backgroundColor] || backgroundColor

  const rgbBgColor = Color(backgroundColor).rgb().string()
  const contrastColor = getContrastColor(rgbBgColor)
  return `text-${contrastColor}`
}

function CollectionHeader({ collection, displaySettings }) {
  const dispatch = useDispatch()

  const titleFont = displaySettings?.displayRosterTitleFont || 'neuton'

  const backgroundColor = displaySettings?.displayProfileBackground
    ? displaySettings.displayRosterBackgroundColor
    : 'white'

  const textColorClass = `text-${getContrastingTextForBackground(backgroundColor)}`

  const hasContactInfo =
    !_.isEmpty(collection?.team_contact_email) ||
    !_.isEmpty(collection?.team_phone) ||
    !_.isEmpty(collection?.team_homepage)

  const activateContactModal = () => {
    if (!hasContactInfo) return
    dispatch(setModalShow({ modal: 'contactInfo', show: true }))
  }

  return (
    <div
      className={joinClassNames([
        `container-fluid`,
        `bg-${backgroundColor}`,
        `bottom-drop-shadow`,
      ])}
    >
      <div className="row align-items-center py-2">
        <div className="col-12 col-md-4">
          {collection.team_image_url ? (
            <div className="team-logo-box d-flex justify-content-center justify-content-md-start px-3">
              <div
                onClick={activateContactModal}
                className={joinClassNames([
                  'team-logo-container',
                  hasContactInfo ? 'selectable' : null,
                ])}
              >
                <img className="" src={collection.team_image_url} />
                {hasContactInfo && (
                  <div className="d-flex flex-column justify-content-center">
                    <button className="btn btn-link" onClick={activateContactModal}>
                      Contact
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="team-name-box d-flex flex-column justify-content-center pb-3 me-2">
              <div className="text-bold ps-3">{collection.team_name}</div>
            </div>
          )}
        </div>
        <div className="col-4">
          <h2
            className={joinClassNames([
              `sheet-header-text text-center font-${titleFont} p-2`,
              textColorClass,
            ])}
          >
            {collection.title}
          </h2>
        </div>
      </div>
    </div>
  )
}

function CollectionTalentSheetItem({ sheet, displaySettings = {} }) {
  const pageLimit = 140

  const dispatch = useDispatch()

  const [talentLoading, setTalentLoading] = useState(false)
  const [currentGroup, setCurrentGroup] = useState(1)
  const searchParams = new URLSearchParams(window.location.search)

  const [error, setError] = useState('')

  const [ref, inView] = useInView({
    triggerOnce: true, // Change this to false if you want to load data every time the item comes into view
  })

  useEffect(() => {
    if (inView) {
      dispatch(
        fetchSingleSheet({
          hash_id: sheet.id,
          page: 1,
          limit: pageLimit,
          with_stats: false,
        }),
      )
    }
  }, [inView])

  const loadNextGroup = () => {
    dispatch(
      fetchSingleSheet({
        hash_id: sheet.id,
        page: currentGroup + 1,
        limit: pageLimit,
        with_stats: false,
      }),
    )
      .then(() => {
        setCurrentGroup(currentGroup + 1)
        setTalentLoading(false)
      })
      .catch((e) => {
        setError('Error loading more')
        setTalentLoading(false)
      })
  }

  const influencers = useSelector((state) => state.influencers.entities)

  let displayedInfluencers = sheet.influencer_ids
    .map((iid) => influencers[iid])
    .filter((inf) => inf)

  const total_influencers = _.get(sheet, 'influencer_ids').length
  const totalPages = Math.ceil(total_influencers / pageLimit)

  const team = useSelector((state) => state.team)

  return (
    <div ref={ref}>
      <RenderRosterItems
        talentSheet={sheet}
        influencer_ids={sheet.influencer_ids}
        totalPages={totalPages}
        currentGroup={currentGroup}
        pageLimit={pageLimit}
        talentLoading={talentLoading}
        setTalentLoading={setTalentLoading}
        sheetIsLoading={false}
        loadNextGroup={loadNextGroup}
        displaySettings={displaySettings}
      />
    </div>
  )
}

export default function AlternateCollections() {
  const [activeId, setActiveId] = useState(null)
  const observer = useRef(null)
  const [show, setShow] = useState(false)

  const socials = useSelector((state) => state.socials.entities)
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(window.location.search)
  const influencers = useSelector((state) => state.influencers.entities)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const dispatch = useDispatch()
  const params = useParams()
  const basicTalentSheets = useSelector((state) => state.collections.sheetBasicInfo)

  const showContactInfo = useSelector((state) => {
    return state.modals.contactInfo === true
  })

  const collection = useSelector((state) => state.collections.collections[params.collectionId])

  const team = useSelector((state) => state.team)

  const displaySettings = useSelector((state) => {
    const collection = state.collections.collections[params.collectionId]
    if (collection && collection.displaySettings) return collection.displaySettings
    return team.settings
  })

  const collectionSheets = !collection
    ? null
    : _.map(collection.salesheet_ids, (id) => {
        return basicTalentSheets[id]
      }).filter((s) => s)

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id)
          }
        })
      },
      { threshold: 0.7 },
    )

    const { current: currentObserver } = observer

    if (collectionSheets) {
      collectionSheets.forEach((sheet) => {
        const element = document.getElementById(`sheet-${sheet.id}`)
        if (element) currentObserver.observe(element)
      })
    }

    return () => {
      if (collectionSheets) {
        collectionSheets.forEach((sheet) => {
          const element = document.getElementById(`sheet-${sheet.id}`)
          if (element) currentObserver.unobserve(element)
        })
      }
    }
  }, [collectionSheets])

  useEffect(() => {
    sendEvent('collection_view', {
      collection_id: params.collectionId,
    })
    dispatch(fetchCollection({ collection_id: params.collectionId, publicAccess: true }))
  }, [dispatch, params.talentSheetId])

  const mainContainerRef = useRef(null)

  const backgroundColor = displaySettings.displayRosterBackgroundColor || 'white'

  const backgroundColorHex = getColorHex(backgroundColor)

  const textColorClass = getBackgroundTextContrast(backgroundColor)

  if (!collection || _.isEmpty(collectionSheets)) {
    return <div>Loading...</div>
  }

  if (!collection) {
    return <div>Loading...</div>
  }

  if (searchParams.get('influencer_id')) {
    const paramInfluencerId = searchParams.get('influencer_id')
    let selectedProfile = _.get(influencers, paramInfluencerId)
    if (_.isEmpty(selectedProfile)) {
      return <LoadingContainer />
    }

    return (
      <PublicProfilev2
        // influencerNote={sheet.notes[selectedProfile.id]}
        influencer={selectedProfile}
        socials={socials}
        showBackArrow={true}
        onBack={() => {
          navigate(-1)
        }}
        settings={{
          ...team.settings,
          // teamLogo: talentSheet.team_image_url,
          // teamName: talentSheet.team_name,
          excluded_post_ids: _.get(selectedProfile, 'team_settings.excluded_post_ids', []),
        }}
        displaySettings={displaySettings}
        // talentSheetId={sheet.id}
      />
    )
  }

  return (
    <>
      <CollectionHeader collection={collection} displaySettings={displaySettings} />
      <div
        ref={mainContainerRef}
        style={{ minHeight: '85vh' }}
        className={`bg-${backgroundColor}`}
      >
        <Button variant="primary" onClick={handleShow} className="position-fixed top-0 end-0 m-3">
          Menu
        </Button>

        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Table of Contents</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="list-group">
              {collectionSheets.map((sheet) => (
                <button
                  key={sheet.id}
                  type="button"
                  className={`list-group-item list-group-item-action ${
                    activeId === `sheet-${sheet.id}` ? 'active' : ''
                  }`}
                  onClick={() => {
                    const element = document.getElementById(`sheet-${sheet.id}`)
                    if (element) element.scrollIntoView({ behavior: 'smooth' })
                    handleClose()
                  }}
                >
                  {sheet.description || 'Selected Talent'}
                </button>
              ))}
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        {collection &&
          _.map(collectionSheets, (sheet) => {
            return (
              <Fragment key={`sheet-${sheet.id}`}>
                <h2
                  className={joinClassNames([
                    `sheet-header-text font-neuton text-center p-2 my-2 py-3`,
                    'text-black',
                  ])}
                  id={`sheet-${sheet.id}`}
                  style={{
                    backgroundColor: 'white',
                  }}
                >
                  {!_.isEmpty(sheet.description) ? sheet.description : 'Selected Talent'}
                </h2>

                <CollectionTalentSheetItem sheet={sheet} displaySettings={displaySettings} />
              </Fragment>
            )
          })}
        <ContactInfoModal
          showModal={showContactInfo}
          handleClose={() => dispatch(setModalShow({ modal: 'contactInfo', show: false }))}
          logoUrl={collection?.team_image_url}
          email={collection?.team_contact_email}
          phoneNumber={collection?.team_phone}
          homepage={collection?.team_homepage}
          bio={collection?.team_bio}
        />
      </div>
    </>
  )
}
