import React, { useState, useEffect, useMemo } from 'react'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import { Talent } from 'helpers/Talent'
import useLocalStorage from 'hooks/useLocalStorage'
import _ from 'lodash'
import {
  fetchOneUserSheet,
  setNameFilter,
  setTagFilters,
  setInfluencersAndSave,
  saveUserSheet,
  setTitle,
  setFilteredInfluencers,
  removeInfluencerAndSave,
  addInfluencerAndSave,
  setCastingCallResponse,
  setSheetType,
  saveNote,
  setSaveStatus,
} from 'redux/userSheetSlice'
import { useParams } from 'react-router'
import { safelyMillify } from 'utils'
import { Responsive, WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import { fetchSheet, fetchSheetsType, fetchSingleSheet } from 'redux/allSheetsSlice'

import {
  EuiSpacer,
  EuiFlexItem,
  EuiSplitPanel,
  EuiPanel,
  EuiBasicTable,
  EuiHorizontalRule,
  EuiImage,
  EuiButton,
  EuiFieldText,
  EuiPopover,
  EuiButtonEmpty,
  EuiTextArea,
  EuiFormRow,
} from '@elastic/eui'

import SpecialTag from 'components/SpecialTag'

import { Link } from 'react-router-dom'

import { css } from '@emotion/react'

import { EuiAvatar } from '@elastic/eui'
import AutoSuggest from 'components/AutoSuggest'
import SocialIcon from 'components/SocialIcon'
import ClickToCopy from 'components/ClickToCopy'
import { Slide, toast } from 'react-toastify'
import axios from 'axios'
import { fetchCastingCalls, fetchResponseSheet } from 'redux/castingCallsSlice'
import { Card } from 'react-bootstrap'
// import DndGrid from 'components/DndGrid'
// import GridExample from 'components/GridExample'
import BasicGrid from 'components/BasicGrid'
import BottomBar from 'components/BottomBar'
import { RiFileEditLine } from 'react-icons/ri'
import { getDisplayName } from 'helpers/Social'
import Switch from 'components/Switch'
import RenderMarkdown from 'components/RenderMarkdown'

const ResponsiveGridLayout = WidthProvider(Responsive)

const RenderGridPanel = ({ influencer }) => {
  const socials = useSelector((state) => state.socials.entities)

  const talent = new Talent(influencer, socials)
  const portraitURL = talent.getPhotoUrl()
  const talentName = talent.influencer?.fullname

  return (
    <Card className="shadow position-relative">
      <Card.Img
        variant="top"
        src={portraitURL}
        style={{ borderRadius: '0', width: '100%', height: '100%' }}
      />
      <Card.ImgOverlay className="d-flex align-items-end justify-content-center p-0">
        <Card.Title className="text-center w-100 bg-white text-black py-2 mb-0">
          {talentName}
        </Card.Title>
      </Card.ImgOverlay>
    </Card>
  )
}

const SocialCard = ({ influencer_id }) => {
  // State for managing the visibility of the popup
  const [isPopupVisible, setIsPopupVisible] = useState(false)

  const influencer = useSelector((state) => state.influencers.entities[influencer_id])
  const socials = useSelector((state) => state.socials.entities)
  const talent = new Talent(influencer, socials)

  const talentSocials = talent.getSocials()

  const dispatch = useDispatch()
  const talentSheet = useSelector((state) => state.userSheet)

  const handleRemove = (influencer_id) => {
    dispatch(removeInfluencerAndSave(influencer_id))
  }

  // Function to toggle the popup
  const togglePopup = () => setIsPopupVisible(!isPopupVisible)

  const notes = useSelector((state) => {
    return state.userSheet.notes
  })

  const [specialNote, setSpecialNote] = useState(notes[influencer_id] || '')

  const accountName =
    talentSocials.primaryAccount?.platform !== 'youtube'
      ? talentSocials.primaryAccount?.platform_account_name
      : null

  // A simple popup content, you can customize it further
  const popupContent = (
    <div className="w-100">
      <EuiTextArea
        placeholder="Display note about influencer (e.g. why are they a good fit for this campaign?)"
        aria-label="Custom description"
        className="w-100"
        value={specialNote}
        onChange={(e) => {
          setSpecialNote(e.target.value)
        }}
      />
      <EuiSpacer />
      <EuiButton
        onClick={() => {
          dispatch(
            saveNote({
              id: influencer_id,
              note: specialNote,
            }),
          )
          togglePopup()
        }}
        size="s"
      >
        Save
      </EuiButton>
    </div>
  )

  return (
    <EuiPanel>
      <div
        // style={{ height: '100%' }}
        className="d-flex justify-content-between align-items-center"
      >
        <div className="d-flex">
          <EuiAvatar
            size="xl"
            name={talent.influencer.fullname}
            imageUrl={talent.getPhotoUrl()}
            style={{ marginRight: '12px' }}
          />
          <div>
            <div style={{ fontWeight: 'bold' }}>{talent.influencer.fullname}</div>
            <div style={{ color: 'gray' }}>{accountName}</div>
          </div>
        </div>
        <div>
          <EuiPopover
            button={
              <EuiButtonEmpty onClick={togglePopup}>
                <RiFileEditLine className="me-1" />
                Include special note
              </EuiButtonEmpty>
            }
            isOpen={isPopupVisible}
            closePopover={togglePopup}
            panelStyle={{ width: '400px' }}
          >
            {popupContent}
          </EuiPopover>
          {/* <EuiLink onClick={togglePopup}>Write custom description</EuiLink> */}
        </div>
      </div>
      <div className="my-2">
        <p className="fw-bold mb-2">{notes[influencer_id] && notes[influencer_id]}</p>
        <RenderMarkdown markdownText={talent.getDescription().slice(0, 150)} />
      </div>
      <EuiHorizontalRule />
      <div className="row">
        <div className="col-10">
          <div className="row">
            {talentSocials.allSocials.map((social, i) => {
              const handle = getDisplayName(social)

              return (
                <div key={`${social.platform}-${i}`} className="col-4 my-1">
                  <div className="d-flex">
                    <SocialIcon platform={social.platform} size={22} />
                    <a href={social.profile_url} target="_blank" rel="noreferrer">
                      <span className="ms-2 text-light-gray text-decoration-underline">
                        {handle} {social.followers && <>| {safelyMillify(social.followers)} </>}
                      </span>
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="col-2 d-flex justify-content-end">
          <button
            style={{ maxHeight: '85px' }}
            className="btn btn-sm btn-bright-red p-3"
            onClick={() => {
              console.log('remove')
              handleRemove(talent.influencer.id)
            }}
          >
            Remove -
          </button>
        </div>
      </div>
    </EuiPanel>
  )
}

const GridCard = ({ influencer_id }) => {
  // State for managing the visibility of the popup
  const [isPopupVisible, setIsPopupVisible] = useState(false)

  const influencer = useSelector((state) => state.influencers.entities[influencer_id])
  const socials = useSelector((state) => state.socials.entities)
  const talent = new Talent(influencer, socials)

  const portraitURL = talent.getPhotoUrl()
  const talentName = talent.influencer?.fullname

  return (
    <div className="d-flex flex-row">
      <div>
        <img
          alt={talentName}
          src={portraitURL}
          // allowFullScreen
          style={{ borderRadius: '10px', height: '35px' }}
        />
      </div>
      <div>
        <p style={{ textAlign: 'center', marginTop: '10px' }}>{talentName}</p>
      </div>
    </div>
  )
}

const AvailableRosterTable = ({ sheetType }) => {
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(40)

  const dispatch = useDispatch()

  const talentSheet = useSelector((state) => state.userSheet)

  const influencers = useSelector((state) => state.influencers.entities)
  const socials = useSelector((state) => state.socials.entities)

  const items = useSelector((state) => state.influencers.entities)

  const user = useSelector((state) => state.user)

  const talentSheetInfluencerIds = useSelector((state) => state.userSheet.influencer_ids)

  const nameFilter = useSelector((state) => state.userSheet.name_filter)

  useEffect(() => {
    let influencerArray = _.filter(items, (influencer) => {
      if (!influencer) return false
      return !talentSheetInfluencerIds.includes(influencer.id)
    })

    if (nameFilter.trim().length > 2) {
      influencerArray = influencerArray.filter((influencer) => {
        return influencer.fullname.toLowerCase().includes(nameFilter.toLowerCase())
      })
    }

    // if there are tags, all of them must match
    if (talentSheet.tag_filters.length > 0) {
      influencerArray = influencerArray.filter((influencer) => {
        return talentSheet.tag_filters.every((tag) => {
          return influencer.tags && influencer.tags.includes(tag)
        })
      })
    }

    influencerArray = _.orderBy(influencerArray, ['fullname'], ['asc'])

    dispatch(setFilteredInfluencers(influencerArray))
  }, [items, talentSheetInfluencerIds, nameFilter, talentSheet.tag_filters])

  // const filteredItems = useSelector((state) => state.userSheet.filteredInfluencers)

  const filteredItems = useMemo(() => {
    // console.log(items)
    let influencerArray = _.filter(items, (influencer) => {
      if (!influencer) return false
      return !talentSheetInfluencerIds.includes(influencer.id)
    })

    if (nameFilter.trim().length > 2) {
      influencerArray = influencerArray.filter((influencer) => {
        return influencer.fullname.toLowerCase().includes(nameFilter.toLowerCase())
      })
    }

    // if there are tags, all of them must match
    if (talentSheet.tag_filters.length > 0) {
      influencerArray = influencerArray.filter((influencer) => {
        return talentSheet.tag_filters.every((tag) => {
          return influencer.tags && influencer.tags.includes(tag)
        })
      })
    }

    influencerArray = _.orderBy(influencerArray, ['fullname'], ['asc'])
    return influencerArray
  }, [items, talentSheetInfluencerIds, nameFilter, talentSheet.tag_filters])

  const actions = useMemo(() => {
    let actions = [
      {
        render: (influencer) => {
          return (
            <button
              className="btn btn-bright-blue py-3"
              onClick={() => {
                dispatch(addInfluencerAndSave(influencer.id))
              }}
            >
              Add +
            </button>
          )
        },
      },
    ]
    return actions
  }, [items])

  const columns = [
    {
      name: 'Talent',
      align: 'left',
      truncateText: false,
      render: (influencer) => {
        const talent = new Talent(influencer, socials)

        return (
          <div className="d-flex flex-row align-items-center">
            <EuiFlexItem
              css={css`
                border-radius: 10px;
                overflow: hidden;
              `}
              grow={false}
            >
              <EuiImage
                size={68}
                hasShadow
                allowFullScreen={false}
                url={talent.getPhotoUrl()}
                alt={talent.influencer.fullname}
              />
            </EuiFlexItem>
            <EuiFlexItem className="fw-bold ms-3">
              <Link
                className="text-black"
                to={`/public/profile/${talent.influencer.id}/team/${user.primary_team_id}`}
              >
                {talent.influencer.fullname}
              </Link>
            </EuiFlexItem>
          </div>
        )
      },
    },
    {
      name: 'Followers (primary)',
      align: 'left',
      truncateText: false,
      render: (influencer) => {
        const talent = new Talent(influencer, socials)
        const influencerSocials = talent.getSocials()

        const primarySocial = influencerSocials.primaryAccount

        return (
          <div className="d-flex flex-row">
            <SocialIcon size={24} platform={primarySocial.platform} />
            <span className="ms-2 pb-2">{safelyMillify(primarySocial.followers)}</span>
          </div>
        )
      },
    },
    {
      field: 'tags',
      name: 'Tags',
      align: 'left',
      truncateText: false,
      mobileOptions: {
        show: false,
      },
      render: (tags) => {
        if (!tags) return null
        return (
          <div>
            {tags.slice(0, 5).map((tag) => {
              return <SpecialTag name={tag} key={tag} color={'#e6fcfc'} />
            })}
            {tags.length > 5 && (
              <EuiFlexItem grow={false}>
                <div>+{tags.length - 5} more</div>
              </EuiFlexItem>
            )}
          </div>
        )
      },
    },
    {
      name: 'Add',
      align: 'center',
      render: (influencer) => {
        return (
          <button
            className="btn btn-bright-blue py-3 px-3"
            onClick={() => {
              dispatch(addInfluencerAndSave(influencer.id))
            }}
          >
            Add +
          </button>
        )
      },
      // actions,
    },
  ]

  useEffect(() => {
    console.log('name filter changed')
    // when this changes, we need to reset the page index to 0
    setPageIndex(0)
  }, [nameFilter])

  const onTableChange = ({ page }) => {
    if (page) {
      const { index: pageIndex, size: pageSize } = page
      setPageIndex(pageIndex)
      setPageSize(pageSize)
    }
  }

  const paginatedItems = filteredItems.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)

  const pagination = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    totalItemCount: filteredItems.length,
    pageSizeOptions: [40, 80],
  }

  return (
    <>
      <EuiSplitPanel.Outer hasShadow={false} hasBorder={false} className="pb-0">
        <EuiSplitPanel.Inner>
          <div style={{ height: '60vh', overflow: 'auto' }} className="eui-scrollBar pb-0">
            <EuiBasicTable
              items={paginatedItems}
              columns={columns}
              pagination={pagination}
              onChange={onTableChange}
            />
          </div>
        </EuiSplitPanel.Inner>
      </EuiSplitPanel.Outer>
    </>
  )
}

const NewEditTalentSheet = ({ panelled, offset, grow, sheetType = 'talent' }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const [editTitle, setEditTitle] = useState(false)
  const [error, setError] = useState(null)

  const [gridView, setGridView] = useLocalStorage(false)

  const talentSheet = useSelector((state) => state.userSheet)
  const talentSheetInfluencers = talentSheet.influencer_ids

  const allInfluencerEntities = useSelector((state) => state.influencers.entities)
  const team_influencer_ids = useSelector((state) => state.influencers.team_influencer_ids)

  const socials = useSelector((state) => state.socials.entities)

  useEffect(() => {
    const load = async () => {
      if (params.talentSheetId && params.talentSheetId !== 'new-talent-sheet') {
        dispatch(fetchOneUserSheet({ hash_id: params.talentSheetId }))
        dispatch(
          fetchSheetsType({
            sheetType: 'talent',
            limit: 10,
          }),
        )
        setEditTitle(false)
      }

      if (params.castingCallId) {
        dispatch(fetchCastingCalls())
        dispatch(fetchResponseSheet(params.castingCallId))
      }
    }
    load()
  }, [dispatch, params.talentSheetId, params.castingCallId])

  useEffect(() => {
    if (params.castingCallId) {
      dispatch(setCastingCallResponse(params.castingCallId))
      dispatch(setSheetType('casting_call_response'))
    } else {
      dispatch(setCastingCallResponse(null))
      dispatch(setSheetType('talent'))
    }
  }, [])

  const filteredItems = useSelector((state) => state.userSheet.filteredInfluencers)

  const handleAddAllClick = () => {
    console.log('add all')
    const new_influencer_ids = [...talentSheet.influencer_ids, ...filteredItems.map((i) => i.id)]
    console.log(new_influencer_ids)
  }

  const nameFilter = useSelector((state) => state.userSheet.name_filter)

  let handleFilterChange = (e) => {
    dispatch(setNameFilter(e.target.value))
  }

  handleFilterChange = _.debounce(handleFilterChange, 350)

  const influencers = useMemo(
    () => _.pick(allInfluencerEntities, team_influencer_ids),
    [allInfluencerEntities, team_influencer_ids],
  )

  const castingCall = useSelector((state) => {
    const castingCall = _.find(state.castingCalls.castingCalls, (cc) => {
      return cc.id === params.castingCallId
    })
    return castingCall
  })

  const suggestedTags = useSelector((state) => state.tags.tags)

  const showSavedToast = ({ customText = 'Talent Sheet Saved' } = {}) => {
    toast.success(customText, {
      transition: Slide,
      position: 'top-center',
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
    })
  }

  const handleSubmitResponse = async () => {
    // this is a creator call response, so we need to submit it
    // no talent sheet id, only casting call id
    const data = {
      casting_call_id: params.castingCallId,
    }

    try {
      const res = await axios.post('/api/user/casting-call-response-submit', data)
      // dispatch(setSaveStatus('submitted'))
      showSavedToast({ customText: 'Response Submitted' })
      dispatch(fetchCastingCalls())
    } catch (e) {
      const error = _.get(e, 'response.data.error')
      setError(error)
      window.setTimeout(() => {
        setError(null)
      }, 2500)
    }
  }

  return (
    <div
      // panelled={panelled}
      // offset={offset}
      // grow={grow}
      // style={{
      //   overflowX: 'hidden',
      // }}
      className="container-fluid bg-subdued eui-scrollBar mt-2"
    >
      {params.castingCallId && (
        <div className="row">
          <div className="col-12">
            {castingCall?.response_status !== 'submitted' ? (
              <div className="container mt-3">
                <div className="alert alert-danger">
                  <p className="my-2" style={{ fontSize: '1.1em' }}>
                    Note: You are preparing a response to the Creator Call. Your changes will be
                    saved, but your sheet will not be visible to the brand until you indicate it is
                    ready using the "Submit" button.
                  </p>
                  <p>
                    <EuiButton
                      fill
                      // size="l"
                      // className="btn btn-info btn-lg text-white"
                      onClick={handleSubmitResponse}
                    >
                      Submit
                    </EuiButton>
                  </p>
                </div>
              </div>
            ) : (
              <>
                {castingCall?.response_status === 'submitted' && (
                  <div className="alert alert-info">
                    <p className="text-center">
                      Your talent sheet has been submitted. Your team will receive a response via
                      email when the brand has had a chance to review your creators.{' '}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="py-0 px-3">
            <div className="container-fluid py-0">
              {sheetType === 'talent' && (
                <div className="my-1">
                  {params.talentSheetId && (
                    <div className="d-flex flex-row fs-5 my-2">
                      <p className="me-3">Public Links: </p>
                      <div className="me-3">
                        <Link
                          data-purpose="public-sheet-link"
                          to={`/public/talent-sheet/${params.talentSheetId}`}
                        >
                          Single-Page View
                        </Link>
                        <ClickToCopy
                          iconId={'page-view-copy-icon'}
                          textToCopy={`${window.location.origin}/public/talent-sheet/${params.talentSheetId}`}
                        />
                      </div>
                      <div>
                        <Link
                          data-purpose="roster-view-public-sheet-link"
                          to={`/public/talent-sheet/roster-view/${params.talentSheetId}`}
                        >
                          Roster View
                        </Link>
                        <ClickToCopy
                          iconId={'roster-view-copy-icon'}
                          textToCopy={`${window.location.origin}/public/talent-sheet/roster-view/${params.talentSheetId}`}
                        />
                      </div>
                    </div>
                  )}
                  <hr />
                </div>
              )}

              <div className="row">
                <div className="col-12 col-md-8">
                  <div className="d-flex flex-row py-3">
                    {sheetType !== 'casting_call_response' && (
                      <>
                        {editTitle ? (
                          <>
                            <EuiFormRow>
                              <EuiFieldText
                                name="name"
                                value={talentSheet.title}
                                onChange={(e) => dispatch(setTitle(e.target.value))}
                              />
                            </EuiFormRow>
                            <div className="d-flex align-items-center">
                              <button
                                className="ms-3 btn btn-lg btn-bright-blue"
                                onClick={() => {
                                  console.log('save title')
                                  dispatch(saveUserSheet())
                                  setEditTitle(false)
                                }}
                                fill
                              >
                                Save
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                fontSize: '2.5rem',
                              }}
                              className="fw-bold"
                            >
                              {talentSheet.title}
                            </div>
                            <button
                              className="btn btn-link text-link-blue"
                              onClick={() => setEditTitle(true)}
                            >
                              <RiFileEditLine className="me-1" />
                              Edit Title
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <EuiPanel paddingSize="s" className="pb-0">
              <div className="container-fluid my-3">
                <div className="row">
                  <div className="col-8">
                    <h2 className="fs-3 fw-bold">Available Roster</h2>
                    <div className="d-flex">
                      <p className="mt-2">Add influencers from the list below to get started</p>
                      <div className="pt-2 ms-4">
                        <Link to={`/advanced-edit-filters/${params.talentSheetId}`}>
                          Advanced Filters
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 d-flex justify-content-end">
                    <button className="btn btn-bright-blue" onClick={handleAddAllClick}>
                      Add All To Sheet
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <EuiFlexItem>
                  <EuiFieldText placeholder="Filter by name" onChange={handleFilterChange} />
                </EuiFlexItem>
                <EuiFlexItem>
                  <AutoSuggest
                    handleAddTagFromForm={(tag) => {
                      console.log('add tag', tag)
                      dispatch(setTagFilters([...talentSheet.tag_filters, tag]))
                    }}
                    handleRemoveTag={(tag) => {
                      console.log('remove tag', tag)
                      dispatch(setTagFilters(talentSheet.tag_filters.filter((t) => t !== tag)))
                    }}
                    currentTags={talentSheet.tag_filters}
                    allChoices={suggestedTags}
                    inputPlaceholder="Filter by tag"
                  />
                </EuiFlexItem>
              </div>

              <AvailableRosterTable sheetType={sheetType} />
            </EuiPanel>
          </div>
        </div>

        <div className="col-12 col-lg-6">
          <EuiPanel paddingSize="l" color="subdued">
            <div className="container-fluid">
              <div className="row">
                <div className="col-6">
                  <h2 className="fs-2 fw-bold">Talent Added to Sheet</h2>
                  {gridView && <p className="mt-2">Drag and drop to change the order</p>}
                </div>
                <div className="col-6">
                  <Switch isRight={!gridView} setIsRight={setGridView} />,
                </div>
              </div>
            </div>
            {!gridView ? (
              <div
                style={{ maxHeight: '75vh', overflow: 'auto', marginTop: '2rem' }}
                className="pe-3 eui-scrollBar"
              >
                {talentSheetInfluencers.map((influencer_id) => {
                  const influencer = influencers[influencer_id]
                  if (!influencer) return null
                  const talent = new Talent(influencer, socials)
                  return (
                    <React.Fragment key={`influencer-${influencer_id}`}>
                      <SocialCard influencer_id={influencer_id} />
                      <EuiSpacer />
                    </React.Fragment>
                  )
                })}
              </div>
            ) : (
              <div style={{ maxHeight: '75vh', overflow: 'auto', marginTop: '2rem' }}>
                <BasicGrid influencer_ids={talentSheetInfluencers} />
              </div>
            )}
          </EuiPanel>
        </div>
      </div>
      {sheetType === 'talent' && (
        <BottomBar>
          {talentSheet.saveStatus === 'clean' ? (
            <button
              className="btn btn-link text-white"
              onClick={() => {
                dispatch(setSaveStatus('dirty'))
                setTimeout(() => {
                  dispatch(saveUserSheet())
                  // dispatch(fetchSheet())
                }, 300)
              }}
            >
              Saved
            </button>
          ) : (
            <button
              className="btn btn-link text-white"
              onClick={() => {
                return null
              }}
            >
              Saving
            </button>
          )}
        </BottomBar>
      )}
    </div>
  )
}

export default NewEditTalentSheet
