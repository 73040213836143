import React, { useState } from 'react'
import { FaRegCopy } from 'react-icons/fa'
import { Tooltip } from 'react-tooltip'

const ClickToCopy = ({ textToCopy, iconId }) => {
  const [tooltip, setTooltip] = useState(false)

  const handleCopyClick = () => {
    navigator.clipboard.writeText(textToCopy)
    setTooltip(true)
    setTimeout(() => {
      setTooltip(false)
    }, 1000)
  }

  return (
    <>
      <span className="m-1">
        <FaRegCopy id={iconId} className="selectable copy-icon" onClick={handleCopyClick} />
      </span>
      <Tooltip
        style={{
          fontSize: '14px',
          padding: '6px',
        }}
        anchorSelect={`#${iconId}`}
        content="Link Copied"
        place="right"
        type="dark"
        effect="solid"
        isOpen={tooltip}
      />
    </>
  )
}

export default ClickToCopy
